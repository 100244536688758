<!-- Created by henian.xu on 2022/5/17. -->

<template>
  <Page>
    <container>
      <div class="qrcode">
        <div class="inner">
          <div class="img-obj">
            <img
              :src="wxQrCodeUrl||$defaultImg"
              alt="qrcode"
            >
          </div>
        </div>
      </div>
      <div class="qrcode-desc">
        <div>长按图片识别二维码，可关注微信公众号。</div>
        <div>进入公众号点击【进入商城】菜单进行购物。</div>
      </div>
      <div class="ctrl">
        <Linker
          v-if="iHealthRegisterUrl"
          class="btn btn-main bor radius"
          :to="iHealthRegisterUrl"
        >
          立即注册
        </Linker>
        <Linker
          v-if="shopUrl"
          class="btn btn-second bor radius"
          :to="shopUrl"
        >
          进入商城
        </Linker>
      </div>
    </container>
  </Page>
</template>

<script>
import pageMixin from 'mixins/layout/page';

export default {
  name: 'RegisterGuidePage',
  mixins: [pageMixin],
  data() {
    return {
      buyerId: this.$route.params.buyerId,
      detail: {},
    };
  },
  computed: {
    wxQrCodeUrl() {
      return this.detail.wxQrCodeUrl;
    },
    shopUrl() {
      return this.detail.shopUrl;
    },
    iHealthRegisterUrl() {
      return this.detail.iHealthRegisterUrl;
    },
  },
  methods: {
    async getDetail() {
      const { buyerId } = this;
      const { data: res } = await this.$api.Buyer.Mb.Buyer.iHealthDetail({
        buyerId,
      });
      this.detail = res.data;
      this.setShareData({
        title: '新零售注册',
        link: this.$route.fullPath,
        successData: {
          urllinkType: 3,
        },
      });
    },
  },
  created() {
    this.getDetail();
  },
};
</script>

<style lang='scss' scoped>
.qrcode {
  padding: $padding;
  width: 80%;
  margin: 60px auto 0 auto;
  > .inner {
    position: relative;
    width: 100%;
    background-color: #fff;
    border-radius: 5px;
    padding-top: 100%;

    > .img-obj {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      padding: $padding;
      > img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
  }
}
.qrcode-desc {
  text-align: center;
  color: $color-text-minor;
}

.ctrl {
  padding: $padding-big * 3;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;

  > .btn {
    padding: $padding;
    + .btn {
      margin-top: $margin;
    }
  }
}
</style>
