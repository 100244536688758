<!-- Created by henian.xu on 2017/10/22. -->

<template>
  <Page :style="pageStyles">
    <container ref="container">
      <Widget
        @decorationData="onDecorationData"
        v-if="decorationJson"
        :json="decorationJson"
      />
    </container>
  </Page>
</template>

<script>
import pageMixin from '@/mixins/layout/page';

export default {
  mixins: [pageMixin],
  name: 'ActivityPage',
  data() {
    return {
      // getJsonDataUrl: '/rpc/buyer/sp/shopDecoration/getActivityPageJson',
      // getAdJsonDataUrl: '/rpc/buyer/sp/shopDecoration/getAdPageJson',
      decorationJson: '',
      pageInfo: {},
    };
  },
  head() {
    const meta = this.$route.meta;
    const { pageTitle } = this;
    if (pageTitle) {
      this.$bus.$emit('pageTitle', pageTitle);
    }
    return {
      title:
        pageTitle ||
        `${meta.title || ''}${meta.subTitle ? ` - ${meta.subTitle}` : ''}`,
    };
  },
  computed: {
    id() {
      return this.$route.params.id;
    },
    pageTitle() {
      const { pageInfo } = this;
      const { props } = pageInfo;
      if (!props || !props.title) return '';
      return props.title;
    },
    pageStyles() {
      const { pageInfo } = this;
      const { props } = pageInfo;
      if (!props || !props.backgroundColor) return {};
      return {
        'background-color': props.backgroundColor,
      };
    },
    isAd() {
      return this.$route.query.hasOwnProperty('ad');
    },
    getJsonDataUrl() {
      return this.isAd
        ? '/rpc/buyer/sp/shopDecoration/getAdPageJson'
        : '/rpc/buyer/sp/shopDecoration/getActivityPageJson';
    },
  },
  methods: {
    apiPageData() {
      this.$http
        .get(this.getJsonDataUrl, {
          id: this.id, // this.getJsonDataId,
          adPageType: this.id, // this.getJsonDataId,
        })
        .then(json => {
          const result = json.data;
          if (!result.success) {
            this.$messageBox.alert(result.msg, '温馨提示');
          } else if (result.data) {
            let res = json.data.data;
            this.decorationJson = res;
          }
        });
    },
    onDecorationData(data) {
      this.pageInfo = data.find(item => item.componentType === 'Page');
      console.log(this.pageInfo);
    },
  },
  created() {
    this.apiPageData();
  },
};
</script>

<style lang="scss" scoped>
</style>
