<!-- Created by henian.xu on 2017/10/22. -->
<template>
  <Page>
    <container ref="container">
      <img
        class="img-obj"
        src="~@/assets/images/icon/icon01.png"
      >
      <div class="btn btn-red radius all link bor big">
        {{ msg }}
      </div>
    </container>
  </Page>
</template>

<script>
import pageMixin from '@/mixins/layout/page';

export default {
  name: 'ShopClose',
  mixins: [pageMixin],
  data() {
    return {};
  },
  computed: {
    msg() {
      return this.$route.query.msg;
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 2rem;

  > .img-obj {
    width: 4rem;
    margin-bottom: 0.5rem;
    opacity: 0.2;
  }
}
</style>
